import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, renderSlot as _renderSlot } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", {
    class: `flex flex-col overflow-hidden mt-${_ctx.marginTop} w-full bg-white`
  }, [
    _createVNode("div", {
      class: 
        `flex gap-x-${_ctx.gap} text-20px mb-${_ctx.marginBottom} ${
          _ctx.contained || _ctx.rounded ? '' : 'border-b border-gray-lp-400'
        }`
      
    }, [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.data, (tab) => {
        return (_openBlock(), _createBlock("button", {
          key: tab.value,
          onClick: ($event: any) => (_ctx.changeTabs(tab.value)),
          class: 
          `text-${_ctx.titleSize}px font-semibold hover:text-red-lp-100 focus:outline-none ${_ctx.styles(
            tab.value
          )} ${
            _ctx.isTabActive(tab.value)
              ? 'text-red-lp-100 border-red-lp-100'
              : 'text-gray-lp-600 border-gray-lp-600'
          }`
        
        }, _toDisplayString(tab.title), 11, ["onClick"]))
      }), 128))
    ], 2),
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}